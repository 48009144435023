import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './main.css'
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient=new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
    <QueryClientProvider client={queryClient}>
      <App />
      </QueryClientProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

