import React from 'react'

const Employees = () => {
  return (
    <div>
      no data now 
    </div>
  )
}

export default Employees
