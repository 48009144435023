import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/ContextProvider";
import Tilt from 'react-parallax-tilt';
import '../main.css'
export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      navigate('/login'); // Redirect to login page after successful signup
    } catch {
      setError("Failed to create an account");
    }
    setLoading(false);
  }
  return (
    <>
      <div className="App bg-white h-screen flex">
        <div className="flex-1">
          <div>
            <div style={{ position: 'fixed', top: '0px',left:'0px', background: '#ffffff',zIndex:'1000',maxWidth:'100%',maxHeight:'100%' }}  className="container h-screen rounded-2xl shadow-5xl relative z-2 flex justify-center items-center ">
              <div className="w-96">
                {error && <Alert variant="danger">{error}</Alert>}
                <form onSubmit={handleSubmit} className="login flex flex-col justify-center items-center rounded" style={{position:'relative' ,background:'white',padding:'50px',lineHeight:'40px',border:'1px solid #e8e3e3'}}>
                  <div className="font-poppins text-2xl tracking-widest mb-4"> <img className="w-[200px] relative left-[5px]" src="https://play-lh.googleusercontent.com/Z_Y9Puq8a3sRItLZdmMJzS1tpuk02EUChIZvY3wKBUNkpDeqoG4-TpFsP3kkupUKmL8=w240-h480-rw" alt="" /> </div>
                  <input type="email" placeholder="Email" className="input-text text-gray-600" ref={emailRef} required />
                  <input type="password" placeholder="Password" className="input-text text-gray-600" ref={passwordRef} required />
                  <input type="password" placeholder='password confirm' className="input-text text-gray-600" ref={passwordConfirmRef} required />
                  <input disabled={loading} type="submit" value="Submit" className="border rounded cursor-pointer font-poppins rounded-full px-5 py-1 bg-white bg-opacity-50 hover:bg-white hover:bg-opacity-80 mt-4" />
                  <div className="w-100 text-center mt-2">
                    Already have an account? <Link to="/login">Log In</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


