import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowToBottom } from 'react-icons/bi';

const DevicesDetail = () => {
  const [editingField, setEditingField] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    active: true,
    name: '',
    group: '',
    deviceTimestamp: '',
    desc: '',
    restartApp: null,
    restartDevice: null,
    OfflinePlaylist: '',
    SilenceTimeEnd: null,
    Address: '',
    City: '',
    OS: '',
    PlayerVer: '',
    StorageFree: '',
    macAddress: '',
    license: '',
    customerId:''
    // offlineCommand: 'IDLE',  // Set the initial value to true or false
    // name: '',
    // customerId: '',
    // goOffline: null ,
    // volume: '',
    // playing: '',
  });
  const [activeAccordion, setActiveAccordion] = useState('');

  const toggleAccordion = (accordionName) => {
    setActiveAccordion((prevAccordion) => (prevAccordion === accordionName ? '' : accordionName));
  };

  const renderAccordion = (accordionName, label, content) => {
    const isActive = activeAccordion === accordionName;

    return (
      <div key={accordionName}>
        <div
          className='py-2 bg-gray-400 rounded pl-2 mb-6 flex items-center justify-between cursor-pointer'
          onClick={() => toggleAccordion(accordionName)}
        >
          <p>{label}</p>
          <div>{isActive ? <BiArrowToBottom /> : <BiArrowToBottom />}</div>
        </div>
        {isActive && <div>{content}</div>}
      </div>
    );
  };
  useEffect(() => {
    // Fetch the customer data if ID is provided
    if (id) {
      // Get the customer document from Firestore using the provided ID
      db.collection('devices')
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // Set the form data with the fetched customer data
            setFormData(doc.data());
          } else {
            console.log('No customer found!');
          }
        })
        .catch((error) => {
          console.error('Error fetching customer: ', error);
        });
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Generate a unique ID for the new customer document if ID is not provided
    const customerId = id ? id : db.collection('devices').doc().id;
    // Create a new customer object with the generated ID
    const newCustomer = {
      id: customerId,
      ...formData,
      
    };

    // Add or update the device document in the "customers" collection
    db.collection('devices')
      .doc(customerId)
      .set(newCustomer)
      .then(() => {
        navigate('/devices');
        setTimeout(() => {
          toast.success('Device changes saved successfully!');
        }, 500); // Delay navigation for 5 seconds
      })
      .catch((error) => {
        console.error('Error saving device: ', error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'active' ? value === 'true' : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };


  const handleEditClick = (fieldName) => {
    setEditingField(fieldName);
  };
  const handleSaveClick = (fieldName) => {
    setEditingField('');
    // Handle any additional logic for saving the field value
  };

  const renderEditButton = (fieldName) => {

    return (
      <button
        className='text-gray-500  rounded focus:outline-none focus:shadow-outline ml-2'
        type='button'
        onClick={() => handleEditClick(fieldName)}
      >
        Edit
      </button>
    );
  };

  const renderInputField = (fieldName, label, placeholder) => {
    const originalValue = formData[fieldName];
  
    const handleCancelClick = () => {
      setEditingField('');
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: originalValue,
      }));
    };
  
    const handleDateChange = (event) => {
      const { value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: value,
      }));
    };
  
    return (
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor={fieldName}>
            {label}
          </label>
          <div className="relative">
            {editingField !== fieldName && (
              <div className="flex">
                {fieldName === 'active' ? (
                  <select
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleChange}
                  >
                    <option value={true}>Online</option>
                    <option value={false}>Offline</option>
                  </select>
                ) : fieldName === 'deviceTimestamp' ? (
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName] || ''}
                    onChange={handleDateChange}
                    type="datetime-local"
                    placeholder={placeholder}
                  />
                ) : (
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                    id={fieldName}
                    name={fieldName}
                    type="text"
                    placeholder={placeholder}
                    value={formData[fieldName] || ''}
                    onChange={handleChange}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  

  return (
    <>
      <div className='mt-4'>
        <form onSubmit={handleSubmit}>
          {renderAccordion('deviceInformation', 'DEVICE INFORMATION', (
            <div>
              {renderInputField('active', 'active', 'active')}
              {renderInputField('name', 'name', 'name')}
              {renderInputField('group', 'group', 'group')}
              {renderInputField('deviceTimestamp', 'deviceTimestamp', 'deviceTimestamp')}
              {renderInputField('desc', 'desc', 'desc')}
            </div>
          ))}

          {renderAccordion('deviceReboot', 'DEVICE REBOOT', (
            <div>
              {renderInputField('restartApp', 'restartApp', 'restartApp')}
              {renderInputField('restartDevice', 'restartDevice', 'restartDevice')}
              {renderInputField('restartApp', 'Daily Restart App', 'Daily Restart App')}
            </div>
          ))}

          {renderAccordion('deviceSettings', 'DEVICE SETTINGS', (
            <div>
              {renderInputField('deviceTimestamp', 'deviceTimestamp', 'deviceTimestamp')}
              {renderInputField('OfflinePlaylist', 'OfflinePlaylist', 'OfflinePlaylist')}
              {renderInputField('SilenceTimeEnd', 'SilenceTimeEnd', 'SilenceTimeEnd')}
            </div>
          ))}

          {renderAccordion('location', 'LOCATION', (
            <div>
              {renderInputField('Address', 'address', 'address')}
              {renderInputField('City', 'City', 'City')}
            </div>
          ))}

          {renderAccordion('report', 'REPORT', (
            <div>
              {renderInputField('playback report', 'playback report', 'playback report')}
            </div>
          ))}

          {renderAccordion('osInformation', 'OS INFORMATION', (
            <div>
              {renderInputField('OS', 'OS', 'OS')}
              {renderInputField('PlayerVer', 'PlayerVer', 'PlayerVer')}
              {renderInputField('StorageFree', 'StorageFree', 'StorageFree')}
              {renderInputField('macAddress', 'macAddress', 'macAddress')}
              {renderInputField('license', 'license', 'license')}
            </div>
          ))}

          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4'
            type='submit'
          >
            Save Changes
          </button>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default DevicesDetail;