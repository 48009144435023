import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';

const AddCustomer = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        active: null, // Set the initial value to true or false
        username: '',
        firstname: '',
        lastname: '',
        city: '',
        address: '',
        phone: '',
        email: '',
        registration: '',
        vat: '',
        company: '',
        country: '',
        pincode: null
    });

    useEffect(() => {
        // Fetch the customer data if ID is provided
        if (id) {
            // Get the customer document from Firestore using the provided ID
            db.collection('customers')
                .doc(id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        // Set the form data with the fetched customer data
                        setFormData(doc.data());
                    } else {
                        console.log('No customer found!');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching customer: ', error);
                });
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Generate a unique ID for the new customer document if ID is not provided
        const customerId = id ? id : db.collection('customers').doc().id;
        // Create a new customer object with the generated ID
        const newCustomer = {
            id: customerId,
            ...formData
        };
        // Add or update the customer document in the "customers" collection
        db.collection('customers')
            .doc(customerId)
            .set(newCustomer)
            .then(() => {
                toast.success('Customer saved successfully!');
                setTimeout(() => {
                    navigate('/customers');
                }, 5000); // Delay navigation for 5 seconds
            })
            .catch((error) => {
                console.error('Error saving customer: ', error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const parsedValue = name === 'active' ? value === 'true' : value;
        setFormData({ ...formData, [name]: parsedValue });
    };
    return (
        <div className='flex justify-center items-center m-20'>
            <form className='w-full' onSubmit={handleSubmit}>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='active'>
                            Status
                        </label>
                        <select
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='active'
                            name='active'
                            value={formData?.active?.toString()} // Convert boolean to string
                            onChange={handleChange}
                        >
                            <option value=''>Select an option</option>
                            <option value={true}>Active</option> // Set string values for options
                            <option value={false}>Inactive</option> // Set string values for options
                        </select>

                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Username
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='username'
                            type='text'
                            placeholder='Enter username'
                            name='username'
                            value={formData.username}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Pin Code
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='pincode'
                            type='password'
                            placeholder='Enter pincode'
                            name='pincode'
                            value={formData.pincode}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Firstname
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='firstname'
                            type='text'
                            placeholder='Enter firstname'
                            name='firstname'
                            value={formData.firstname}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Lastname
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='lastname'
                            type='text'
                            placeholder='Enter lastname'
                            name='lastname'
                            value={formData.lastname}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            City
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='city'
                            type='text'
                            placeholder='Enter city'
                            name='city'
                            value={formData.city}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Address
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='address'
                            type='text'
                            placeholder='Enter address'
                            name='address'
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Phone
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='phone'
                            type='text'
                            placeholder='Enter phone'
                            name='phone'
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Email
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='email'
                            type='text'
                            placeholder='Enter email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Registration
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='registration'
                            type='date'
                            placeholder='Enter registration'
                            name='registration'
                            value={formData.registration}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Vat
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='vat'
                            type='text'
                            placeholder='Enter vat'
                            name='vat'
                            value={formData.vat}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Company
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='company'
                            type='text'
                            placeholder='Enter company'
                            name='company'
                            value={formData.company}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='firstname'>
                            Country
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='country'
                            type='text'
                            placeholder='Enter country'
                            name='country'
                            value={formData.country}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                {/* Add other fields similarly */}

                <div className='flex justify-center justify-start'>
                    <button
                        className='border text-gray-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                        type='submit'
                    >
                        Submit
                    </button>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default AddCustomer;
