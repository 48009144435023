import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import CustomerDetail from '../pages/addForm/CustomerDetail';

const CustomerRightBox = () => {
  const navigate=useNavigate()
  const { currentColor } = useStateContext();

  return (
    <div className="bg-half-transparent w-full fixed nav-item top-0 right-0 overflow-scroll h-screen">
      <div className="float-right h-screen  duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-gray-100 md:w-400 p-8" style={{height:'160rem'}}>
        <div>
          <p className="font-semibold text-lg cursor-pointer" onClick={()=>navigate('/customers')}><MdOutlineCancel /></p>
          <CustomerDetail/>
        </div>
      </div>
    </div>
  );
};

export default CustomerRightBox;
