  import React, { useEffect, useState, useCallback } from 'react';
  import { useTable, usePagination, useSortBy } from 'react-table';
  import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
  import { CiRouter } from 'react-icons/ci'
  import { FiMoreHorizontal } from 'react-icons/fi'
  import { Loader } from '../commons/Loader/Loader';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useNavigate } from 'react-router-dom';
  import { db } from '../firebase';
  import { Dialog, Transition } from '@headlessui/react';
  import { Cart } from '../components';
  import { useStateContext } from '../contexts/ContextProvider';
  import { database } from 'firebase';
  import Form from 'react-bootstrap/Form';
  const Customers = () => {

    // const [isClicked, setIsClicked, setNameData] = useState({});
    const [nameData, setNameData] = useState([]); // Add this line
    const { handleClick, isClicked, setIsClicked } = useStateContext();    // const [setIsClicked] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [rows, setRows] = useState([]);
    const [updatedRows, setUpdatedRows] = useState(rows);
    console.log('updatedRows', updatedRows)
    const [volume, setVolume] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [goOffline, setgoOffline] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]); // Track filtered users
    const [searchTerm, setSearchTerm] = useState('');
    console.log('searchTerm',)
    const [users, setUsers] = useState([]);
    console.log('users', users)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    const [isOpen, setIsOpen] = useState(false);
    const [del, getDel] = useState(null);
    const [clickedUserDevices, setClickedUserDevices] = useState([]);


    const handleShowDevices = (devices) => {
      setClickedUserDevices(devices || []);
    };

    // useEffect(() => {
    //   // This will log the updated value of clickedUserDevices whenever it changes.
    //   console.log('clickedUserDevices:', clickedUserDevices);
    // }, [clickedUserDevices]);


    const handleDelete = (userId) => {
      // Delete the customer from Firebase
      db.collection('customers')
        .doc(userId)
        .delete()
        .then(() => {
          // Customer deleted successfully
          toast.success('Customer deleted');
          setIsOpen(false);
        })
        .catch((error) => {
          // Error occurred while deleting the customer
          toast.error('Failed to delete customer');
          console.error('Error deleting customer:', error);
        });
    };

    useEffect(() => {
      // Fetch the customers data from Firebase
      const unsubscribe = db.collection('customers').onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setIsLoading(false);
        setUsers(data);

        // Fetch the devices data from Firebase
        db.collection('devices')
          .get()
          .then((snapshot) => {
            const devicesData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            // Associate devices data with users data
            const updatedUsers = data.map((user) => {
              const userDevices = devicesData.filter((device) => device.userId === user.id);
              return {
                ...user,
                devices: userDevices,
              };
            });

            // Update the rows with updated users data
            setUpdatedRows(updatedUsers);
          })
          .catch((error) => {
            // Handle error if needed
            console.error('Error fetching devices:', error);
          });
      });

      return () => unsubscribe();
    }, []);


    useEffect(() => {
      const unsubscribe = db.collection('customers').onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setIsLoading(false)
        setUsers(data);
        setFilteredUsers(data);
        setUpdatedRows(data);
      });

      return () => unsubscribe();
    }, []);
    useEffect(() => {
      const filteredData = users.filter((user) => {
        const { active, address, firstname, lastname, city, email, username, phone, registration, vat, company, country, id } = user;
        const searchValue = searchTerm.toLowerCase();

        return (
          (active ? 'active' : 'inactive').toLowerCase().includes(searchValue) ||
          (address && address.toLowerCase().includes(searchValue)) ||
          (firstname && firstname.toLowerCase().includes(searchValue)) ||
          (lastname && lastname.toLowerCase().includes(searchValue)) ||
          (city && city.toLowerCase().includes(searchValue)) ||
          (phone && phone.toLowerCase().includes(searchValue)) ||

          (id && id.toLowerCase()?.includes(searchValue)) ||
          (vat && vat.toLowerCase()?.includes(searchValue)) ||
          (company && company.toLowerCase()?.includes(searchValue)) ||
          (country && country.toLowerCase()?.includes(searchValue)) ||
          (registration && typeof registration === 'string' && registration.toLowerCase()?.includes(searchValue)) ||

          (username && username && username.toLowerCase().includes(searchValue)) ||
          (email && email.toLowerCase().includes(searchValue))
        );
      });

      setFilteredUsers(filteredData);

    }, [searchTerm, users]);


    const getNameByCustomerId = useCallback(async (customerId) => {
      try {
        const querySnapshot = await db
          .collection('devices')
          .where('customerID', '==', customerId)
          .get();

        if (querySnapshot.empty) {
          console.log('No documents found for the customerId:', customerId);
          return [];
        }

        const names = querySnapshot.docs.map((doc) => doc.data().name);

        console.log('The Names:', names);

        return names;
      } catch (error) {
        console.log('Error getting documents:', error);
        return [];
      }
    }, []); // Added useCallback dependencies
    const handleClicked = useCallback(async (customerId) => {
      try {
        console.log('Clicked customerId:', customerId);
        const names = await getNameByCustomerId(customerId);
        setNameData(names);
        setClickedUserDevices(names); // Set the device names for the clicked user
        console.log('Imena:',names, typeof(names))
      } catch (error) {
        console.error('Error handling click:', error);
      }
    }, []);

    const data = React.useMemo(() => rows, [rows]);
    const columns = React.useMemo(
      () => [
        {
          Header: 'Status',
          accessor: 'active',
          Cell: ({ value }) => {
            let statusColor = '';
            let statusText = '';
        
            if (typeof value === 'object') {
              statusText = Object.values(value).join(' ');
            } else {
              statusText = value ? 'active' : 'inactive';
            }
        
            if (value) {
              statusColor = 'green';
            } else {
              statusColor = 'red';
            }
        
            return (
              <span style={{ background: statusColor,padding:'6px 12px' ,color:'white',borderRadius:'10px'}}>
                {statusText}
              </span>
            );
          },
        },      
        {
          Header: 'Username',
          accessor: 'username',
          Cell: ({ value }) => {
            if (typeof value === 'object') {
              // If the value is an object, convert it to a string without quotes
              return Object.values(value).join(' ');
            }
            return value || 'N/A';
          },
          // Enable sorting for this column
          sortType: 'alphanumeric',
          disableSortBy: false,
        },
        {
          Header: 'Firstname',
          accessor: 'firstname',
          Cell: ({ value }) => {
            if (typeof value === 'object') {
              // If the value is an object, convert it to a string without quotes
              return Object.values(value).join(' ');
            }
            return value || 'N/A';
          },
          // Enable sorting for this column
          sortType: 'alphanumeric',
          disableSortBy: false,
        },
        {
          Header: 'Lastname',
          accessor: 'lastname',
          Cell: ({ value }) => {
            if (typeof value === 'object') {
              // If the value is an object, convert it to a string without quotes
              return Object.values(value).join(' ');
            }
            return value || 'N/A';
          },
          // Enable sorting for this column
          sortType: 'alphanumeric',
          disableSortBy: false,
        },
        {
          Header: 'City',
          accessor: 'city',
          Cell: ({ value }) => {
            if (typeof value === 'object') {
              // If the value is an object, convert it to a string without quotes
              return Object.values(value).join(' ');
            }
            return value || 'N/A';
          },
          // Enable sorting for this column
          sortType: 'alphanumeric',
          disableSortBy: false,
        },
        {
          Header: 'Country',
          accessor: 'country',
          Cell: ({ value }) => {
            if (typeof value === 'object') {
              // If the value is an object, convert it to a string without quotes
              return Object.values(value).join(' ');
            }
            return value || 'N/A';
          },
          // Enable sorting for this column
          sortType: 'alphanumeric',
          disableSortBy: false,
        },
        {
          Header: 'Phone',
          accessor: 'phone',
          Cell: ({ value }) => {
            if (typeof value === 'object') {
              // If the value is an object, convert it to a string without quotes
              return Object.values(value).join(' ');
            }
            return value || 'N/A';
          },
          // Enable sorting for this column
          sortType: 'alphanumeric',
          disableSortBy: false,
        },
        {
          Header: 'Email',
          accessor: 'email',
          Cell: ({ value }) => {
            if (typeof value === 'object') {
              // If the value is an object, convert it to a string without quotes
              return Object.values(value).join(' ');
            }
            return value || 'N/A';
          },
          // Enable sorting for this column
          sortType: 'alphanumeric',
          disableSortBy: false,
        },

        {
          Header: 'Devices',
          accessor:'devices',
          Cell: ({ row }) => ( // objekat konvertovati u string
            <div className="flex flex-col items-center space-y-2">
              <CiRouter
                className="text-4xl cursor-pointer"
                onClick={() => handleClicked(row.original.id)}
              />
              {/* Display the device names */}
              {row.original.id === nameData?.[0]?.userId && (
                <div>
                  {nameData.map((device, index) => (
                    <span key={index}>{device}</span>
                  ))}
                </div>
              )}
            </div>
      ),
    },
        {
          Header: 'More',
          Cell: ({ row }) => (
            <div className="flex justify-center space-x-2">
              <button className="bg-transparent text-blue-300 font-semibold hover:text-grey py-2 px-4" customFunc={() => handleClick('cart')} onClick={() => navigate(`/update-customers/${row.original.id}`)}><FiMoreHorizontal className='text-3xl' /></button>
              {isClicked?.cart && (<Cart />)}
            </div>
          ),
        },
        {
          Header: 'Actions',
          Cell: ({ row }) => (
            <div className="flex justify-center space-x-2">
              <button
                onClick={() => { setIsOpen(true); getDel(row.original.id) }}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Delete
              </button>
            </div>
          ),
        },
      ],
      []
    );
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data: updatedRows,
        data: filteredUsers,
        initialState: { pageIndex: 0, pageSize: 15 },
      },
      useSortBy,
      usePagination
    );

    useEffect(() => {
      const fetchDevices = async () => {
        const snapshot = await db.collection('devices').get();
        const devicesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
          // Associate devices data with users data
        const updatedUsers = users.map((user) => {
          const userDevices = devicesData.filter((device) => device.userId === user.id);
          return {
            ...user,
            devices: userDevices,
          };
        });
    
          // Update the rows with updated users data
          setRows(updatedUsers);
        };
    
        fetchDevices();
      }, []);

    useEffect(() => {
      // Fetch the initial data from Firebase and populate `updatedRows`
      const fetchData = async () => {
        const snapshot = await database().ref('customers').once('value');
        const devices = snapshot.val();
        if (devices) {
          const updatedRowsData = Object.keys(devices).map((id) => ({
            id,
            volume: devices[id].volume || 0,
            playing: devices[id].playing || false,
            // Include other fields as needed
          }));
          setUpdatedRows(updatedRowsData);
        }
      };

      fetchData();
    }, [volume, playing]);
    const handleSearch = useCallback((event) => {
      setSearchTerm(event.target.value.toLowerCase()); // Update search term on input change
    }, []);
    return (
      <>
        <ToastContainer />
        {isLoading ? (<Loader />) : (
          <div className="m-2 md:m-10 mt-24 p-2 md:p-10 text-gray-700 rounded-3xl static">
            <div className="flex items-center justify-between py-3 mt-10">
              <div>
                <input
                  type="text"
                  placeholder="search..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="border border-gray-300 rounded py-2 px-4 w-full focus:outline-none focus:ring-1 focus:ring-blue-500"
                />
              </div>
            </div>
            
            <div className="w-full">
              <div className="overflow-scroll">
                <div className="flex flex-col">
                  <table {...getTableProps()} className="text-sm text-center rounded text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-sky-200 dark:bg-gray-200">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              className="px-6 py-3 cursor-pointer"
                            >
                              {column.render('Header')}
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <span className="ml-1">&#x2193;</span>
                                ) : (
                                  <span className="ml-1">&#x2191;</span>
                                )
                              ) : null}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="text-center">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                          >
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center" style={{zIndex:'-1'}}>
                <span className="mr-2">Page:</span>
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="disabled:opacity-50 px-2 py-1 rounded-l-md text-white"
                >
                </button>
                <button
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                  className="disabled:opacity-50 px-2 py-1 bg-blue-500 text-white"
                >
                  <AiOutlineArrowLeft />
                </button>
                <button
                  onClick={nextPage}
                  disabled={!canNextPage}
                  className="disabled:opacity-50 px-2 py-1 bg-blue-500 text-white"
                >
                  <AiOutlineArrowRight />
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="disabled:opacity-50 px-2 py-1 rounded-r-md text-white"
                >
                </button>
                <span className="ml-2">
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
              </div>
              <div>
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="px-2 py-1 border border-gray-300 rounded-md bg-blue-500"
                >
                  {[10, 50, 100, 500].map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <>
              <Transition.Root show={isOpen} as={React.Fragment}>
                <Dialog
                  as="div"
                  className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
                  onClose={() => setIsOpen(false)}
                >
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-transparent opacity-30" />
                  </Transition.Child>

                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    {/* Wrap the content inside a container */}
                    <div className="bg-gray-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left relative left-[110px]">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Delete Confirmation
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse  relative right-[110px]">
                        <button
                          onClick={() => handleDelete(del)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 ml-2 rounded"
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => setIsOpen(false)}
                          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Transition.Child>
                </Dialog>
              </Transition.Root>
            </>
          </div>
        )}
      </>
    );
  };

  export default Customers;
