import {
    CircleLoader
  } from 'react-spinners';
  
 export const Loader = () => {
    return (
      <div className="flex justify-center items-center h-[70vh]">
        <CircleLoader
          color="#aeb3b3" loading={true} height={4} width={200} />
      </div>
    );
  };