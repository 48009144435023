import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/ContextProvider';
import Login from './Login';


const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Login/>;
  }
  return children;
};

export default ProtectedRoute;