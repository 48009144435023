import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Cart, Navbar,Sidebar, ThemeSettings } from './components';
import { Ecommerce, Orders, Calendar, Employees, Pyramid, Customers, Kanban, Line, Area, Bar, Pie, Financial, ColorPicker, Editor } from './pages';
import './App.css';

import {  useStateContext } from './contexts/ContextProvider';
import AddCustomer from './pages/addForm/AddCustomer';
import Dashboard from './components/Dashboard';
import UpdateProfile from './components/UpdateProfile';
import Signup from './components/Signup';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ProtectedRoute from './components/PrivateRoute';
import PageNotFound from './pages/PageNotFound';
import CustomerDetail from './pages/addForm/CustomerDetail';
import Devices from './pages/Devices';
import DevicesDetails from './pages/DevicesDetails';
import CustomerRightBox from './components/CustomerRightBox';
import DeviceRightBox from './components/DeviceRightBox';
import AddDevice from './pages/addForm/AddDevice';

const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, themeSettings } = useStateContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSidebarNavbar, setShowSidebarNavbar] = useState(true); // State variable to control the visibility
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }

    // Check if the user is logged in here (e.g., by checking a token in localStorage or making an API call)
    var userLoggedIn = /* Check if the user is logged in */
    setIsLoggedIn(userLoggedIn);
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent
              content="Settings"
              position="Top"
            >
              {/* <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button> */}
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div>
              <Navbar />
            </div>
            <div>
              {themeSettings && (<ThemeSettings />)}
              <Routes>
                <Route
                  path='/dashboard'
                  element={
                    <ProtectedRoute>
                      <Ecommerce />
                    </ProtectedRoute>
                  }
                />

                {/* pages  */}
                <Route path="/orders" element={<Orders />} />
                <Route path="/employees" element={<Employees />} />
                <Route
                  path='/customers'
                  element={
                    <ProtectedRoute>
                      <Customers />
                    </ProtectedRoute>
                  }
                />
                   <Route
                  path='/devices'
                  element={
                    <ProtectedRoute>
                      <Devices />
                    </ProtectedRoute>
                  }
                />
                   <Route
                  path='/devicesDetails'
                  element={
                    <ProtectedRoute>
                      <DevicesDetails />
                    </ProtectedRoute>
                  }
                />
                
                <Route
                  path='/update-customers/:id'
                  element={
                    <ProtectedRoute>
                      <CustomerRightBox />
                    </ProtectedRoute>
                  }
                />
                    
                    <Route
                  path='/update-devices/:id'
                  element={
                    <ProtectedRoute>
                      <DeviceRightBox />
                    </ProtectedRoute>
                  }
                />
                    <Route
                  path='/Add customer'
                  element={
                    <ProtectedRoute>
                      <AddCustomer />
                    </ProtectedRoute>
                  }
                />
                
                <Route
                  path='/Add device'
                  element={
                    <ProtectedRoute>
                      <AddDevice />
                    </ProtectedRoute>
                  }
                />
                {/* apps  */}
                <Route
                  path='/kanban'
                  element={
                    <ProtectedRoute>
                      <Kanban />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/editor'
                  element={
                    <ProtectedRoute>
                      <Editor />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/calendar'
                  element={
                    <ProtectedRoute>
                      <Calendar />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/color-picke'
                  element={
                    <ProtectedRoute>
                      <ColorPicker />
                    </ProtectedRoute>
                  }
                />

                {/* charts  */}
                <Route
                  path='/line'
                  element={
                    <ProtectedRoute>
                      <Line />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/area'
                  element={
                    <ProtectedRoute>
                      <Area />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/bar'
                  element={
                    <ProtectedRoute>
                      <Bar />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/pie'
                  element={
                    <ProtectedRoute>
                      <Pie />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/financial'
                  element={
                    <ProtectedRoute>
                      <Financial />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/pyramid'
                  element={
                    <ProtectedRoute>
                      <Pyramid />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/'
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route path="/update-profile" element={<UpdateProfile />} />
                {!isLoggedIn && <Route path="/signup" element={<Signup />} />}
                {!isLoggedIn && <Route path="/login" element={<Login />} />}
                {!isLoggedIn && <Route path="/forgot-password" element={<ForgotPassword />} />}
                <Route path='*' element={<PageNotFound showSidebarNavbar={showSidebarNavbar} />} />
              </Routes>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;