import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';

const AddDevice = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        active: true,
    name: '',
    group: '',
    deviceTimestamp: '',
    desc: '',
    restartApp: null,
    restartDevice: null,
    OfflinePlaylist: '',
    SilenceTimeEnd: null,
    Address: '',
    City: '',
    OS: '',
    PlayerVer: '',
    StorageFree: '',
    macAddress: '',
    license: ''
    });
    const getDeviceTimestamp = () => {
        return new Date().toISOString();
      };

    useEffect(() => {
        // Fetch the customer data if ID is provided
        if (id) {
            // Get the customers document from Firestore using the provided ID
            db.collection('devices')
                .doc(id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        // Set the form data with the fetched customer data
                        setFormData(doc.data());
                    } else {
                        console.log('No customer found!');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching customer: ', error);
                });
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Generate a unique ID for the new customer document if ID is not provided
        const customerId = id ? id : db.collection('devices').doc().id;

        const deviceTimestamp = getDeviceTimestamp();

        // Create a new device object with the generated ID
        const newDevice = {
            id: customerId,
            ...formData,
            deviceTimestamp: deviceTimestamp,
        };
        
        

        // Add or update the device document in the "devices" collection
        db.collection('devices')
            .doc(customerId)
            .set(newDevice)
            .then(() => {
                toast.success('Device saved successfully!');
                setTimeout(() => {
                    navigate('/devices');
                }, 5000); // Delay navigation for 5 seconds
            })
            .catch((error) => {
                console.error('Error saving device: ', error);
            });
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        const parsedValue = type === 'checkbox' ? e.target.checked : value;
        setFormData({
            ...formData,
            [name]: name === 'deviceTimestamp' ? getDeviceTimestamp() : parsedValue,
          });
    };
    return (
        <div className='flex justify-center items-center m-20'>
            <form className='w-full' onSubmit={handleSubmit}>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='active'>
                            Status
                        </label>
                        <select
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='active'
                            name='active'
                            value={formData.active} // Used to convert boolean to string here
                            
                            onChange={handleChange}
                        >
                            {/* <option value=''>Select an option</option> */}
                            <option value={true}>Active</option> // Set string values for options
                            <option value={false}>Inactive</option> // Set string values for options
                        </select>

                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='customerID'>
                            Customer ID
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='customerID'
                            type='text'
                            placeholder='Enter customerID'
                            name='customerID'
                            value={formData.customerId}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                 <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='name'>
                            Name
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='name'
                            type='text'
                            placeholder='Enter username'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
               
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='group'>
                        Group
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='group'
                            type='text'
                            placeholder='Enter group'
                            name='group'
                            value={formData.group}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='desc'>
                        desc
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='desc'
                            type='text'
                            placeholder='Enter description'
                            name='desc'
                            value={formData.desc}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='macAddress'>
                        macAddress
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='macAddress'
                            type='text'
                            placeholder='Enter macAddress'
                            name='macAddress'
                            value={formData.macAddress}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='City'>
                            City
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='City'
                            type='text'
                            placeholder='Enter city'
                            name='City'
                            value={formData.City}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='Address'>
                            Address
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='Address'
                            type='text'
                            placeholder='Enter address'
                            name='Address'
                            value={formData.Address}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='OS'>
                            OS
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='OS'
                            type='text'
                            placeholder='Enter OS'
                            name='OS'
                            value={formData.OS}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='PlayerVer'>
                            Player Version
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='PlayerVer'
                            type='text'
                            placeholder='Enter PlayerVer'
                            name='PlayerVer'
                            value={formData.PlayerVer}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='license'>
                            License
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='license'
                            type='text'
                            placeholder='Enter license'
                            name='license'
                            value={formData.license}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='StorageFree'>
                            Free Storage
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='StorageFree'
                            type='text'
                            placeholder='Enter free storage'
                            name='StorageFree'
                            value={formData.StorageFree}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='OfflinePlaylist'>
                            Offline Playlist
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='OfflinePlaylist'
                            type='text'
                            placeholder='Enter offline playlist'
                            name='OfflinePlaylist'
                            value={formData.OfflinePlaylist}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='deviceTimestamp'>
                            Device Timestamp
                        </label>
                        <input
                            required
                            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                            id='deviceTimestamp'
                            type='hidden'
                            placeholder='Enter device timestamp'
                            name='deviceTimestamp'
                            value={formData.deviceTimestamp}
                            onChange={handleChange}
                        />
                    </div>
                </div> 
                {/* Add other fields similarly */}

                <div className='flex justify-center justify-start'>
                    <button
                        className='border text-gray-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                        type='submit'
                    >
                        Submit
                    </button>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default AddDevice;
