
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyDf_H3W2BeKoCcfDahL9YgfHnDMXxoILKw",
  authDomain: "mdj-project-2023.firebaseapp.com",
  projectId: "mdj-project-2023",
  storageBucket: "mdj-project-2023.appspot.com",
  messagingSenderId: "601792727213",
  appId: "1:601792727213:web:6ee7d6fe9786d8000bde24",
  measurementId: "G-R38L7GWDLH"

};

const app = initializeApp(firebaseConfig);
const auth = app.auth();
export { auth };
const db = app.firestore();
export { db };
export default app;

