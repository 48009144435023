import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { CiRouter } from 'react-icons/ci'
import { FiMoreHorizontal } from 'react-icons/fi'
import { Loader } from '../commons/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { Dialog, Transition } from '@headlessui/react';
import { Cart } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { database } from 'firebase';
import Form from 'react-bootstrap/Form';
const DevicesDetails = () => {

  const { isClicked, handleClick, nameData, setNameData } = useStateContext();
  const [setIsClicked] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [rows, setRows] = useState([]);
  const [updatedRows, setUpdatedRows] = useState(rows);
  const [volume, setVolume] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [goOffline, setgoOffline] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]); // Track filtered users
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState(nameData);
  const navigate = useNavigate();
  //   const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isOpen, setIsOpen] = useState(false);
  const [del, getDel] = useState(null);



  const data = React.useMemo(() => users, [users]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Device Name',
        accessor: 'name',
        Cell: ({ row }) => {
          const name = row.original.name;
          if (typeof name === 'object') {
            return Object.values(name).join(' ');
          }
          return name || 'N/A';
        },
        sortType: 'alphanumeric',
        disableSortBy: false,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: updatedRows,
      data: filteredUsers,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useSortBy,
    usePagination
  );
  const handleSearch = (event) => {
    setSearchTerm(event.target.value); // Update search term on input change
  };
  return (
    <>
      <ToastContainer />
      {'' ? (<Loader />) : (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 text-gray-700 rounded-3xl static">
          <div className="flex items-center justify-between py-3 mt-10">
            <div>
              <input
                type="text"
                placeholder="search..."
                value={searchTerm}
                onChange={handleSearch}
                className="border border-gray-300 rounded py-2 px-4 w-full focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="overflow-scroll">
              <div className="flex flex-col">
                <table {...getTableProps()} className="text-sm text-center rounded text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-sky-200 dark:bg-gray-200 flex justify-items-start ">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className="px-6 py-3 cursor-pointer"
                          >
                            {column.render('Header')}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="ml-1">&#x2193;</span>
                              ) : (
                                <span className="ml-1">&#x2191;</span>
                              )
                            ) : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className='flex justify-items-start flex-col'>
                    {users.map((row) => ( // Use the users array instead of page
                      <tr
                        key={row} // Add a unique key for each row
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 "
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          {row}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center">
              <span className="mr-2">Page:</span>
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="disabled:opacity-50 px-2 py-1 rounded-l-md text-white"
              >
              </button>
              <button
                onClick={previousPage}
                disabled={!canPreviousPage}
                className="disabled:opacity-50 px-2 py-1 bg-blue-500 text-white"
              >
                <AiOutlineArrowLeft />
              </button>
              <button
                onClick={nextPage}
                disabled={!canNextPage}
                className="disabled:opacity-50 px-2 py-1 bg-blue-500 text-white"
              >
                <AiOutlineArrowRight />
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="disabled:opacity-50 px-2 py-1 rounded-r-md text-white"
              >
              </button>
              <span className="ml-2">
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="px-2 py-1 border border-gray-300 rounded-md bg-blue-500"
              >
                {[10, 50, 100, 500].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <>
            <Transition.Root show={isOpen} as={React.Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
                onClose={() => setIsOpen(false)}
              >
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-transparent opacity-30" />
                </Transition.Child>

                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {/* Wrap the content inside a container */}
                  <div className="bg-gray-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left relative left-[110px]">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Delete Confirmation
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse  relative right-[110px]">
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 ml-2 rounded"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => setIsOpen(false)}
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </Dialog>
            </Transition.Root>
          </>
        </div>
      )}
    </>
  );
};

export default DevicesDetails;
