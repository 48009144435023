import React, { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/ContextProvider";
import '../main.css'
export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history("/");
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }
  return (
    <div className="App bg-white h-screen flex ">
      <div className="flex-1">
        <div>
          <div style={{ position: 'fixed', top: '0px', left: '0px', background: '#ffffff', zIndex: '1000', maxWidth:'100%',maxHeight:'100%'}} className="login fixed container h-screen rounded-2xl shadow-5xl relative z-2 flex justify-center items-center ">
            <div className="w-96">
              {error && <Alert variant="danger">{error}</Alert>}
              <form onSubmit={handleSubmit} className="mid-[320px]:mr-20 flex flex-col justify-center items-center rounded login " style={{ position: 'relative', background: 'white', padding: '50px', lineHeight: '40px', border: '1px solid #e8e3e3' }}>
                <div className="font-poppins text-2xl tracking-widest mb-4"> <img className="w-[200px] relative left-[5px]" src="https://play-lh.googleusercontent.com/Z_Y9Puq8a3sRItLZdmMJzS1tpuk02EUChIZvY3wKBUNkpDeqoG4-TpFsP3kkupUKmL8=w240-h480-rw" alt="" /> </div>
                <input type="email" placeholder="Email" className="input-text text-gray-600" ref={emailRef} required />
                <input type="password" placeholder="Password" className="input-text text-gray-600" ref={passwordRef} required />
                <input disabled={loading} type="submit" value="Submit" className="border rounded cursor-pointer font-poppins rounded-full px-5 py-1 bg-white bg-opacity-50 hover:bg-white hover:bg-opacity-80 mt-4" />
                {/* <div disabled className="w-100 text-center mt-3">
                  <Link  to="/forgot-password">Forgot Password?</Link>
                </div> */}
                <div className="w-100 text-center mt-2">
                  Need an account? <Link to="/signup">Sign Up</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}