import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';

const CustomerDetail = () => {
  const [editingField, setEditingField] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    active: null, // Set the initial value to true or false
    username: '',
    firstname: '',
    lastname: '',
    city: '',
    address: '',
    phone: '',
    email: '',
    registration: '',
    vat: '',
    company: '',
    country: '',
    pincode: null,
  });

  useEffect(() => {
    // Fetch the customer data if ID is provided
    if (id) {
      // Get the customer document from Firestore using the provided ID
      db.collection('customers')
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // Set the form data with the fetched customer data
            setFormData(doc.data());
          } else {
            console.log('No customer found!');
          }
        })
        .catch((error) => {
          console.error('Error fetching customer: ', error);
        });
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Generate a unique ID for the new customer document if ID is not provided
    const customerId = id ? id : db.collection('customers').doc().id;
    // Create a new customer object with the generated ID
    const newCustomer = {
      id: customerId,
      ...formData,
    };

    // Add or update the customer document in the "customers" collection
    db.collection('customers')
      .doc(customerId)
      .set(newCustomer)
      .then(() => {
        navigate('/customers');
        setTimeout(() => {
          toast.success('Customer changes saved successfully!');
        }, 500); // Delay navigation for 5 seconds
      })
      .catch((error) => {
        console.error('Error saving customer: ', error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'active' ? value === 'true' : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };

  const handleEditClick = (fieldName) => {
    setEditingField(fieldName);
  };
  const handleSaveClick = (fieldName) => {
    setEditingField('');
    // Handle any additional logic for saving the field value
  };

  const renderEditButton = (fieldName) => {

    return (
      <button
        className='border text-gray-500 py-1 px-2 rounded focus:outline-none focus:shadow-outline ml-2'
        type='button'
        onClick={() => handleEditClick(fieldName)}
      >
        Edit
      </button>
    );
  };

  const renderInputField = (fieldName, label, placeholder) => {
    const originalValue = formData[fieldName];
  
    const handleCancelClick = () => {
      setEditingField('');
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: originalValue,
      }));
    };
  
    const handleDateChange = (event) => {
      const { value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: value,
      }));
    };
  
    return (
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor={fieldName}>
            {label}
          </label>
          <div className="relative">
            {fieldName === 'active' ? (
              <select
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                id={fieldName}
                name={fieldName}
                value={formData[fieldName]?.toString() || ''}
                onChange={handleChange}
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            ) : fieldName === 'deviceTimestamp' ? (
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                id={fieldName}
                name={fieldName}
                value={formData[fieldName] || ''}
                onChange={handleDateChange}
                type="datetime-local"
                placeholder={placeholder}
              />
            ) : (
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                id={fieldName}
                name={fieldName}
                type="text"
                placeholder={placeholder}
                value={formData[fieldName] || ''}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  

  return (
    <div className='mt-4'>
      <form onSubmit={handleSubmit}>
        {renderInputField('active', 'Status', 'Status')}
        {renderInputField('username', 'Username', 'Username')}
        {renderInputField('firstname', 'First Name', 'First Name')}
        {renderInputField('lastname', 'Last Name', 'Last Name')}
        {renderInputField('city', 'City', 'City')}
        {renderInputField('address', 'Address', 'Address')}
        {renderInputField('phone', 'Phone', 'Phone')}
        {renderInputField('email', 'Email', 'Email')}
        {renderInputField('registration', 'Registration', 'Registration')}
        {renderInputField('vat', 'VAT', 'VAT')}
        {renderInputField('company', 'Company', 'Company')}
        {renderInputField('country', 'Country', 'Country')}
        {renderInputField('pincode', 'Pincode', 'Pincode')}
        <button
          className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4'
          type='submit'
        >
          Save Changes
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CustomerDetail;