import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { CiRouter } from 'react-icons/ci'
import { FiMoreHorizontal } from 'react-icons/fi'
import { Loader } from '../commons/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { Dialog, Transition } from '@headlessui/react';
import { Cart } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { database } from 'firebase';
import Switch from 'react-switch';

const Devices = () => {
  const { isClicked, handleClick, nameData, setNameData } = useStateContext();
  const [setIsClicked] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [rows, setRows] = useState([]);
  const [updatedRows, setUpdatedRows] = useState(rows);
  console.log('updatedRows', updatedRows)
  const [volume, setVolume] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [goOffline, setgoOffline] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]); // Track filtered users
  const [searchTerm, setSearchTerm] = useState('');
  console.log('searchTerm',)
  const [users, setUsers] = useState([]);
  console.log('devices', users)
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isOpen, setIsOpen] = useState(false);
  const [del, getDel] = useState(null);

  const handleDelete = (deviceId) => {
    // Delete the device from Firebase
    db.collection('devices')
      .doc(deviceId) // Use the deviceId as the Firestore document ID
      .delete()
      .then(() => {
        // Device deleted successfully
        toast.success('Device deleted');
        setIsOpen(false);
      })
      .catch((error) => {
        // Error occurred while deleting the device
        toast.error('Failed to delete device');
        console.error('Error deleting device:', error);
      });
  };
  useEffect(() => {
  const unsubscribe = db.collection('devices').onSnapshot((snapshot) => {
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setIsLoading(false);
    setUsers(data);
    setFilteredUsers(data);
    setUpdatedRows(data);
  });

  return () => unsubscribe();
}, []);
  useEffect(() => {
    const filteredData = users.filter((user) => {
      const { active, offlineCommand, address, name, id, customerId } = user;
      const searchValue = searchTerm.toLowerCase();

      return (
        (active ? 'online' : 'offline').toLowerCase().includes(searchValue) ||
        (offlineCommand ? 'IDLE' : 'WAKE_UP').toLowerCase().includes(searchValue) ||
        (address && address.toLowerCase().includes(searchValue)) ||
        (name && name.toLowerCase().includes(searchValue)) ||
        (id && id.toLowerCase().includes(searchValue)) ||
        (customerId && customerId.toLowerCase().includes(searchValue))
      );
    });

    setFilteredUsers(filteredData);

  }, [searchTerm, users]);

  const getNameByCustomerId = async (customerId) => {
    try {
      const querySnapshot = await db
        .collection(' ')
        .where('customerId', '==', customerId)
        .get();

      if (querySnapshot.empty) {
        console.log('No documents found for the customerId:', customerId);
        return [];
      }

      const names = querySnapshot.docs.map((doc) => doc.data().name);

      setNameData(names)
      return names;
    } catch (error) {
      console.log('Error getting documents:', error);
      return [];
    }
  };

  ///asasasa
  const handleClicked = async (customerId) => {
    // const customerId = 'Offline';
    const name = await getNameByCustomerId(customerId);
    // Use the retrieved name as needed
    console.log('Retrieved name:', name);
    navigate('/devicesDetails')
  };


  const data = React.useMemo(() => filteredUsers, [filteredUsers]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'active',
        Cell: ({ value }) => {
          let statusColor = '';
          let statusText = '';
      
          if (typeof value === 'object') {
            statusText = Object.values(value).join(' ');
          } else {
            statusText = value ? 'online' : 'offline';
          }
      
          if (value) {
            statusColor = 'green';
          } else {
            statusColor = 'red';
          }

          return (
            <span style={{ background: statusColor, padding: '6px 15px', borderRadius: '10px', color: 'white' }}>
              {statusText}
            </span>
          );
        },
        // Enable sorting for this column
        sortType: 'alphanumeric',
        disableSortBy: false,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => {
          if (typeof value === 'object') {
            return Object.values(value).join(' ');
          }
          return value || 'N/A';
        },
        // Enable sorting for this column
        sortType: 'alphanumeric',
        disableSortBy: false,
      },
      {
        Header: 'Device Id',
        accessor: 'id',
        Cell: ({ value }) => {
          if (typeof value === 'object') {
            return Object.values(value).join(' ');
          }
          return value || 'N/A';
        },
        // Enable sorting for this column
        sortType: 'alphanumeric',
        disableSortBy: false,
      },
      {
        Header: 'Go Offline',
        accessor: 'goOffline',
        Cell: ({ row }) => {
          const originalRow = row?.original;
          const goOffline = originalRow?.goOffline || false;

          return (
            <div>
              <Switch
                checked={goOffline}
                onChange={() => {
                  setgoOffline(!goOffline);
                  setCurrentPageIndex(0);
                  if (originalRow) {
                    db.collection('devices').doc(originalRow.id).update({ goOffline: !goOffline });
                  }
                }}
                className='text-xs'
                onColor="#00ff00"
                offColor="#ff0000"
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>

          );
        },
      },

      {
        Header: 'Volume',
        accessor: 'volume',
        Cell: ({ row }) => {
          const originalRow = row?.original;
          const volume = originalRow?.volume || 0;

          return (
            <div className='flex'>
              <input
                type="range"
                min={0}
                max={100}
                value={volume}
                onChange={(e) => {
                  const newVolume = parseInt(e.target.value);
                  setVolume(newVolume);
                  setCurrentPageIndex(0);
                  if (originalRow) {
                    db.collection('devices').doc(originalRow.id).update({ volume: newVolume });
                  }
                }}
              />
              <p className='ml-4'>{volume} %</p>
            </div>
          );
        },
      },

      {
        Header: 'Playing',
        accessor: 'playing',
        Cell: ({ row }) => {
          const originalRow = row?.original;
          const playing = originalRow?.playing || false;

          return (
            <div>
              <Switch
                checked={playing}
                onChange={() => {
                  setgoOffline(!playing);
                  setCurrentPageIndex(0);
                  if (originalRow) {
                    db.collection('devices').doc(originalRow.id).update({ playing: !playing });
                  }
                }}
                className='text-xs'
                onColor="#00ff00"
                offColor="#ff0000"
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>

          );
        },
      },
      // {
      //   Header: 'Devices',
      //   Cell: ({ row }) => (
      //     <div className="flex justify-center space-x-2">
      //       <CiRouter className='text-4xl cursor-pointer' onClick={() => handleClicked(row.original.id)} />
      //     </div>
      //   ),
      // },

      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-2">
            <button
              onClick={() => { setIsOpen(true); getDel(row.original.id) }}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        ),
      },
      {
        Header: 'Session Id',
        accessor: 'SessionId',
        Cell: ({ value }) => {
          if (typeof value === 'object') {
            return Object.values(value).join(' ');
          }
          return value || 'N/A';
        },
        // Enable sorting for this column
        sortType: 'alphanumeric',
        disableSortBy: false,
      },
      {
        Header: 'Password',
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-1">
          </div>
        ),
      },
      {
        Header: 'Connect',
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-1">
            <button
              onClick={() => { }}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Connect
            </button>
          </div>
        ),
      },
      {
        Header: 'More',
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-2">
            <button className="bg-transparent text-blue-300 font-semibold hover:text-grey py-2 px-4" customFunc={() => handleClick('cart')} onClick={() => navigate(`/update-devices/${row.original.id}`)}><FiMoreHorizontal className='text-3xl' /></button>
            {isClicked?.cart && (<Cart />)}
          </div>
        ),
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: updatedRows,
      data: filteredUsers,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    // Fetch the initial data from Firebase and populate `updatedRows`
    const fetchData = async () => {
      const snapshot = await database().ref('devices').once('value');
      const devices = snapshot.val();
      if (devices) {
        const updatedRowsData = Object.keys(devices).map((id) => ({
          id,
          volume: devices[id].volume || 0,
          playing: devices[id].playing || false,
          // Include other fields as needed
        }));
        setUpdatedRows(updatedRowsData);
      }
    };

    fetchData();
  }, [volume, playing]);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value); // Update search term on input change
  };
  return (
    <>
      <ToastContainer />
      {isLoading ? (<Loader />) : (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 text-gray-700 rounded-3xl static">
          <div className="flex items-center justify-between py-3 mt-10">
            <div>
              <input
                type="text"
                placeholder="search..."
                value={searchTerm}
                onChange={handleSearch}
                className="border border-gray-300 rounded py-2 px-4 w-full focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="overflow-scroll">
              <div className="flex flex-col">
                <table {...getTableProps()} className="text-sm text-center rounded text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-sky-200 dark:bg-gray-200">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className="px-2 py-3 cursor-pointer"
                          >
                            {column.render('Header')}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="ml-1">&#x2193;</span>
                              ) : (
                                <span className="ml-1">&#x2191;</span>
                              )
                            ) : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="text-center">
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                        >
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center" style={{zIndex:'-1'}}>
              <span className="mr-2">Page:</span>
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="disabled:opacity-50 px-2 py-1 rounded-l-md text-white"
              >
              </button>
              <button
                onClick={previousPage}
                disabled={!canPreviousPage}
                className="disabled:opacity-50 px-2 py-1 bg-blue-500 text-white"
              >
                <AiOutlineArrowLeft />
              </button>
              <button
                onClick={nextPage}
                disabled={!canNextPage}
                className="disabled:opacity-50 px-2 py-1 bg-blue-500 text-white"
              >
                <AiOutlineArrowRight />
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="disabled:opacity-50 px-2 py-1 rounded-r-md text-white"
              >
              </button>
              <span className="ml-2">
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="px-2 py-1 border border-gray-300 rounded-md bg-blue-500"
              >
                {[10, 50, 100, 500].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <>
            <Transition.Root show={isOpen} as={React.Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
                onClose={() => setIsOpen(false)}
              >
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-transparent opacity-30" />
                </Transition.Child>

                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {/* Wrap the content inside a container */}
                  <div className="bg-gray-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left relative left-[110px]">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Delete Confirmation
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse  relative right-[110px]">
                      <button
                        onClick={() => handleDelete(del)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 ml-2 rounded"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => setIsOpen(false)}
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </Dialog>
            </Transition.Root>
          </>
        </div>
      )}
    </>
  );
};

export default Devices;
